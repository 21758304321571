import React, { useEffect } from 'react';
import { createColumnHelper, useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import Table from 'react-bootstrap/Table';
import useApi from '../services/api';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useFormik } from 'formik';
import { date, object } from 'yup';
import useSwal from '../services/messages';


type Sincronizacao = {
    requested_date: string
    from_date: string
    to_date: string
    status: string
}


const Sincronizacoes: React.FC = () => {
    const [data, setData] = React.useState<any[]>([]);
    const api = useApi();
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const Swal = useSwal();

    useEffect(() => {
        api.get('events/sync-requests/').then(resp => setData(resp.data));
    }, [])


    const columnHelper = createColumnHelper<Sincronizacao>();

    const columns = [
        columnHelper.accessor('requested_date', {
            header: 'Data da Requisição',
            enableSorting: true,
            cell: info => {
                var requested_date = new Date(info.getValue());

                return `${requested_date.toLocaleDateString()} às ${requested_date.toLocaleTimeString()}`
            },
            footer: info => info.column.id
        }),
        columnHelper.display({
            header: 'Período Solicitado',
            cell: props => {
                var from_date = new Date(props.row.original.from_date);
                var to_date = new Date(props.row.original.from_date);

                return `${from_date.toLocaleDateString()} a ${to_date.toLocaleDateString()}`
            },
            footer: info => info.column.id,
        }),
        columnHelper.accessor('status', {
            header: 'Status',
            cell: info => info.getValue(),
            footer: info => info.column.id,
        }),
    ]

    const table = useReactTable<Sincronizacao>({
        columns: columns,
        data: data,
        getCoreRowModel: getCoreRowModel(),
        manualSorting: true
    })

    const handleShowModal = () => {
        formik.resetForm();
        setShowModal(true);
    }

    const handleCloseModal = () => {
        setShowModal(false);
    }

    const formik = useFormik({
        initialValues: {
            from_date: undefined,
            to_date: undefined
        },
        validationSchema: object({
            from_date: date().required(),
            to_date: date().required()
        }),
        validateOnMount: true,
        onSubmit: async (values, actions) => {
            try
            {
                actions.setSubmitting(true);

                const response = await api.post('events/sync-requests/', values);

                if (response.status == 201) {
                    handleCloseModal();

                    await Swal.fire(
                        'Sua solicitação está sendo processada em segundo plano',
                        '',
                        'success'
                    )
                }
            }
            finally
            {
                actions.setSubmitting(false);
            }
        }
    });

    return (
        <>
            <h1>Sincronizações <Button onClick={handleShowModal}>Nova requisição</Button></h1>

            <Modal show={showModal} >
                <Form onSubmit={formik.handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Requisitar sincronização</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Data Inicial</Form.Label>
                            <Form.Control
                                id="from_date"
                                name="from_date"
                                type="date"
                                onChange={formik.handleChange}
                                value={formik.values.from_date}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Data Final</Form.Label>
                            <Form.Control
                                id="to_date"
                                name="to_date"
                                type="date"
                                onChange={formik.handleChange}
                                value={formik.values.to_date}
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Cancelar
                        </Button>
                        <Button type="submit" variant="primary" disabled={formik.isSubmitting || !formik.isValid}>
                            Confirmar
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Table striped bordered hover>
                <thead>
                    {
                        table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                                {
                                    headerGroup.headers.map(header => (
                                        <th key={header.id}>
                                            {
                                                header.isPlaceholder ?
                                                null :
                                                flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )
                                            }
                                        </th>
                                    ))
                                }
                            </tr>
                        ))
                    }
                </thead>

                <tbody>
                    {
                        table.getRowModel().rows.map(row => (
                            <tr key={row.id}>
                                {
                                    row.getVisibleCells().map(cell => (
                                        <td key={cell.id}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    ))
                                }
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </>
    )
}

export default Sincronizacoes;