import React, { useEffect } from "react";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GoogleLogin from '../../components/GoogleLogin';
import useApi from "../../services/api";
import { useFormik } from "formik";
import { object, string } from "yup";
import BotaoSalvar from "../../components/BotaoSalvar";


const Configuracoes: React.FC<{}> = () => {
    const api = useApi();
    const [calendars, setCalendars] = React.useState<any[]>();

    const formLabelColumn = 3;

    useEffect(() => {
        getCalendars();
        getConfiguration();
    }, []);

    const getCalendars = async () => {
        const response = await api.get('events/calendars/');

        setCalendars(response.data);
    }

    const getConfiguration = async () => {
        const response = await api.get('auth/configuration/');

        formik.setValues(response.data);
    }

    const formik = useFormik({
        initialValues: {
            calendar_id: '',
            spreadsheet_id: ''
        },
        validationSchema: object({
            calendar_id: string().required(),
            spreadsheet_id: string().required()
        }),
        validateOnMount: true,
        onSubmit: async (values, actions) => {
            try
            {
                actions.setSubmitting(true);

                const response = await api.post('auth/configuration/', values);

                // if (response.status == 201) {
                //     await Swal.fire(
                //         'Sua solicitação está sendo processada em segundo plano',
                //         '',
                //         'success'
                //     )
                // }
            }
            finally
            {
                actions.setSubmitting(false);
            }
        }
    })

    return (
        <>
            <h1>Configurações</h1>

            <Row>
                <Col sm={formLabelColumn}>
                    Conta Google conectada
                </Col>
                <Col sm={12 - formLabelColumn}>
                    <GoogleLogin />
                </Col>
            </Row>

            <Form onSubmit={formik.handleSubmit}>
                <Form.Group as={Row} className="mt-3">
                    <Form.Label column={true} sm={formLabelColumn}>Calendário</Form.Label>
                    <Col sm={12 - formLabelColumn}>
                        <Form.Select
                            id="calendar_id"
                            name="calendar_id"
                            disabled={calendars == null}
                            onChange={formik.handleChange}
                            value={formik.values.calendar_id}
                        >
                            <option>
                                {
                                    calendars ?
                                    'Selecione um calendário' :
                                    'Consultando os seus calendários'
                                }
                            </option>
                            {
                                calendars?.map((value, index) => <option value={value.id} key={value.id}>{value.nome}</option>)
                            }
                        </Form.Select>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mt-3">
                    <Form.Label column={true} sm={formLabelColumn}>Id da Spreadsheet</Form.Label>
                    <Col sm={12 - formLabelColumn}>
                        <Form.Control
                            id="spreadsheet_id"
                            name="spreadsheet_id"
                            onChange={formik.handleChange}
                            value={formik.values.spreadsheet_id}
                        />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mt-3">
                    <Col>
                        <BotaoSalvar
                            isSubmitting={formik.isSubmitting}
                            isValid={formik.isValid}
                        />
                    </Col>
                </Form.Group>
            </Form>
        </>
    )
}

export default Configuracoes