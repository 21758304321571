import React from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import useApi from '../services/api';
import GoogleLogin from '../components/GoogleLogin';
import Modal from 'react-bootstrap/Modal';
import { useFormik } from 'formik';
import { object, date } from 'yup';
import Swal from 'sweetalert2';


const Dashboard: React.FC = () => {
    const api = useApi();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [calendars, setCalendars] = React.useState<any[]>();
    const [calendar, setCalendar] = React.useState<any>();
    const [showModal, setShowModal] = React.useState<boolean>(false);

    const getCalendars = async () => {
        try
        {
            setLoading(true);
            const response = await api.get('events/calendars/');

            setCalendars(response.data);
        }
        finally
        {
            setLoading(false);
        }
    }

    const syncEvents = async () => {
        try
        {
            setLoading(true);
            await api.post(`events/calendars/${calendar}/events/`);
        }
        finally
        {
            setLoading(false);
        }
    }

    const selectCalendar = (event: any) => {
        setCalendar(event.target.value);
    }

    const handleShowModal = () => {
        setShowModal(true);
    }

    const handleCloseModal = () => {
        setShowModal(false);
    }

    const formik = useFormik({
        initialValues: {
            from_date: undefined,
            to_date: undefined
        },
        validationSchema: object({
            from_date: date().required(),
            to_date: date().required()
        }),
        validateOnMount: true,
        onSubmit: async (values, actions) => {
            try
            {
                actions.setSubmitting(true);

                const response = await api.post('events/sync-requests/', values);

                if (response.status == 201) {
                    handleCloseModal();

                    await Swal.fire(
                        'Sua solicitação está sendo processada em segundo plano',
                        '',
                        'success'
                    )
                }
            }
            finally
            {
                actions.setSubmitting(false);
            }
        }
    })

    return (
        <>
            <GoogleLogin />

            <Row>
                <Col>
                    <Button onClick={getCalendars} disabled={loading}>Obter Calendários</Button>
                </Col>
                {
                    calendars &&
                    <Col>
                        <Form.Select aria-label="Default select example" onChange={selectCalendar}>
                            <option>Open this select menu</option>
                            {
                                calendars.map((value, index) => <option value={value.id}>{value.nome}</option>)
                            }
                        </Form.Select>
                    </Col>
                }
            </Row>

            <Row>
                <Button onClick={syncEvents} disabled={!calendar || loading}>Sincronizar Eventos</Button>
            </Row>

            <Row>
                <Button onClick={handleShowModal}>Abrir Modal</Button>
            </Row>

            <Modal show={showModal} >
                <Form onSubmit={formik.handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Requisitar sincronização</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Data Inicial</Form.Label>
                            <Form.Control
                                id="from_date"
                                name="from_date"
                                type="date"
                                onChange={formik.handleChange}
                                value={formik.values.from_date}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Data Final</Form.Label>
                            <Form.Control
                                id="to_date"
                                name="to_date"
                                type="date"
                                onChange={formik.handleChange}
                                value={formik.values.to_date}
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Cancelar
                        </Button>
                        <Button type="submit" variant="primary" disabled={formik.isSubmitting || !formik.isValid}>
                            Confirmar
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default Dashboard;