import React from "react";
import Button from 'react-bootstrap/Button';

interface BotaoSalvarProps {
    isSubmitting: boolean
    isValid: boolean
}

const BotaoSalvar: React.FC<BotaoSalvarProps> = (props: BotaoSalvarProps) => {
    return <Button type="submit" disabled={props.isSubmitting || !props.isValid}>Salvar</Button>
}

export default BotaoSalvar;