import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import Button from 'react-bootstrap/Button';


const GoogleLogin: React.FC = () => {
  const login = useGoogleLogin({
    flow: 'auth-code',
    ux_mode: 'redirect',
    redirect_uri: `${process.env.REACT_APP_APP_URL}/google_login_callback/`,
    scope: 'https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/spreadsheets',
  });

  return (
    <>
      <Button onClick={() => login()}>
        Sign in with Google 🚀{' '}
      </Button>
    </>
  );
}

export default GoogleLogin;
