import { useFormik } from 'formik';
import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { object, string } from 'yup';
import AuthContext from '../contexts/Auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import './login.css';
import useSwal from '../services/messages';


const LoginPage: React.FC = () => {
    const context = React.useContext(AuthContext);
    const swal = useSwal();

    const validationSchema = object({
        login: string().required(),
        password: string().required()
    });

    const form = useFormik({
        initialValues: {
            login: '',
            password: ''
        },
        validationSchema: validationSchema,
        validateOnMount: true,
        onSubmit: async (values, actions) => {
            if (context.login) {
                try
                {
                    actions.setSubmitting(true);
                    await context.login(values.login, values.password);
                }
                finally
                {
                    actions.setSubmitting(false);
                }
            }
        }
    });

    return (
        <div className="login_wrapper">
            <div className="login_box">
                <h1>Login</h1>

                <Form onSubmit={form.handleSubmit}>
                    <Form.Group>
                        <Form.Label>Usuário</Form.Label>
                        <Form.Control
                            id="login"
                            name="login"
                            onChange={form.handleChange}
                            value={form.values.login}
                            autoFocus
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Senha</Form.Label>
                        <Form.Control
                            id="password"
                            name="password"
                            type="password"
                            onChange={form.handleChange}
                            value={form.values.password}
                        />
                    </Form.Group>

                    <Button type="submit" disabled={form.isSubmitting === true || form.isValid === false}>
                        {
                            form.isSubmitting === false &&
                            <>
                                <FontAwesomeIcon icon={solid('right-to-bracket')} /> Entrar
                            </>
                        }
                        {
                            form.isSubmitting === true &&
                            <FontAwesomeIcon icon={solid('circle-notch')} className="fa-spin" />
                        }
                    </Button>
                </Form>
            </div>
        </div>
    )
}

export default LoginPage;