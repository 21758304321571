import React, { createContext } from 'react';
import useApi from '../services/api';

interface IAuthProps {
  user?: boolean
  login?: (username: string, password: string) => Promise<void>
  logout?: () => Promise<void>,
  getUserData: () => string | null,
  refreshToken: () => Promise<void>,
}

const AuthContext = createContext<IAuthProps>({} as IAuthProps);

export const AuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [user, setUser] = React.useState<any>(localStorage.getItem('USER_DATA'));
    const api = useApi();

    const login = async (username: string, password: string) => {
      const response = await api.post('auth/login/', {
        username: username,
        password: password
      });

      setUser(response.data);

      localStorage.setItem('USER_DATA', JSON.stringify(response.data));
    }

    const logout = async () => {
      // const response = await api.post('auth/logout/');

      setUser(null);

      localStorage.removeItem('USER_DATA');
    }

    const getUserData = () => {
      return localStorage.getItem('USER_DATA');
    }

    const refreshToken = async () => {
      const userData = getUserData();

      if (userData) {
        const userDataJson = JSON.parse(userData);

        const response = await api.post('auth/token/refresh/', {
          refresh: userDataJson.refresh
        });

        userDataJson['access'] = response.data.access;

        localStorage.setItem('USER_DATA', JSON.stringify(userDataJson));
      }
    }

    return (
      <AuthContext.Provider value={{ user, login, logout, getUserData: getUserData, refreshToken: refreshToken }}>
        {children}
      </AuthContext.Provider>
    );
};

export default AuthContext;