import React, { useEffect } from "react";
import useApi from "../../services/api";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";


const GoogleLoginCallback: React.FC<{}> = () => {
    const api = useApi();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        saveCode();
    }, []);

    const saveCode = async () => {
        await api.post('/auth/google-login-callback/', {
            code: searchParams.get('code')
        });

        navigate('/configuracoes');
    }

    return (
        <>
        </>
    )
}

export default GoogleLoginCallback