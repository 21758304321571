import React from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import useSwal from './messages';
import AuthContext from '../contexts/Auth';


const useApi = () => {
    const context = React.useContext(AuthContext);

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL
    });

    const swal = useSwal();

    api.interceptors.request.use(
        (config: AxiosRequestConfig<any>) => {
            const userData = localStorage.getItem('USER_DATA');

            if (userData) {
                const userDataJson = JSON.parse(userData)

                // if (userDataJson.access) {
                //     const headers = {
                //         'Authorization': `Bearer ${userDataJson.access}`
                //     };

                //     config.headers = {...config.headers, ...headers};
                // }
            }

            return config;
        },
        (error) => error
    )

    api.interceptors.response.use(
        (value) => value,
        (error) => {
            if (error.response.status == 401) {
                return context.refreshToken().then(() => {
                    const userData = context.getUserData();

                    if (userData) {
                        error.config.headers['Authorization'] = `Bearer ${JSON.parse(userData).access}`;
                    }

                    return axios.request(error.config);
                }).catch(() => {
                    if (context?.logout) {
                        context.logout();
                    }
                })
            } else if (error.response.status == 400) {
                let errorMessage;

                if (error.response.data.non_field_errors) {
                    errorMessage = error.response.data.non_field_errors[0]
                } else {
                    errorMessage = 'Erro não especificado'
                }

                swal.fire({
                    title: errorMessage,
                    icon: 'error'
                })
            } else if (error.response.status == 500) {
                swal.fire({
                    title: 'Ocorreu um erro interno',
                    icon: 'error'
                })
            }
        }
    )

    return api;
}

export default useApi;