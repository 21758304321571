import React from 'react';
import { Navigate, Route, Routes as RoutesDom } from 'react-router-dom';
import LoginPage from '../pages/LoginPage';
import MainPage from '../pages/MainPage';
import Dashboard from '../pages/Dashboard';
import AuthContext from '../contexts/Auth';
import Sincronizacoes from '../pages/Sincronizacoes';
import Configuracoes from '../pages/configuracoes/Configuracoes';
import GoogleLoginCallback from '../pages/GoogleLoginCallback/GoogleLoginCallback';

const Routes: React.FC = () => {
  const context = React.useContext(AuthContext);

  return (
    <>
      <RoutesDom>
        {
          context.user &&
          <>
            <Route path='/' element={<MainPage />}>
              <Route path="" element={<Dashboard />} />
              <Route path="sincronizacoes" element={<Sincronizacoes />} />
              <Route path="configuracoes" element={<Configuracoes />} />
              <Route path="google_login_callback" element={<GoogleLoginCallback />} />
            </Route>
            <Route path='/login' element={<Navigate to="/" replace={true} />} />
          </>
        }
        {
          !context.user &&
          <>
            <Route path='/login' element={<LoginPage />} />
            <Route path='*' element={<Navigate to="/login" replace={true} />} />
          </>
        }
      </RoutesDom>
    </>
  );
}

export default Routes;
