import React from 'react';
import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router';
import Header from '../components/main/Header';


const MainPage: React.FC = () => {
    return (
        <>
            <Header />

            <Container>
                <Outlet />
            </Container>
        </>
    )
}

export default MainPage;